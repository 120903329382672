import { QueryClient } from "react-query";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createLocalStoragePersistor } from "react-query/createLocalStoragePersistor-experimental";

let queryClient;

function getQueryClient() {
  if (!queryClient) {
    queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          cacheTime: 1000 * 60 * 60 * 2, // 2 hours
        },
      },
    });

    persistQueryClient({
      queryClient,
      persistor: createLocalStoragePersistor(),
      maxAge: 1000 * 60 * 60 * 2, // 2 hours
      buster: "RESET_CACHE|19-02-2020|11:17PM",
    });
  }

  return queryClient;
}

export default getQueryClient;
